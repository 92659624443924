import React from "react";
import ParticleComponent from "../components/particles";
import { getAboutPageContent } from "../services/pages.service";

import Layout from "../components/layout";
import Footer from "../components/Footer";
import about_img from "../images/numericaideas.png";

const AboutPage = ({ serverData }) => {
  const pageContent = serverData.content;
  let dataSEO = {
    title: "About",
    description: "About NumericaIdeas and its network",
    image: about_img,
    url: "https://www.numericaideas.com/about",
  };

  return (
    <Layout seoData={dataSEO}>
      <ParticleComponent>
        <div id="main" style={{ display: "flex", width: "100%" }}>
          <article
            id="about"
            className="active timeout"
            style={{ width: "90%" }}
          >
            <div
              key={`about`}
              id="about"
              dangerouslySetInnerHTML={{
                __html: pageContent,
              }}
            />
          </article>
        </div>
        <Footer/>
      </ParticleComponent>
    </Layout>
  );
};

export default AboutPage;

export async function getServerData() {
  return await getAboutPageContent();
}
